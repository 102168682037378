import '../styles/index.scss';
import $ from 'jquery';
import 'bootstrap';
import lax from 'lax.js';
import "particles.js";
import AOS from 'aos';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

window.onload = function() {
	particlesJS.load('animated', 'public/particles.json', function() {
		console.log('Casi todo listo...');
	});
	AOS.init();

	lax.setup(); // init

	const updateLax = () => {
		lax.update(window.scrollY);
		window.requestAnimationFrame(updateLax);
	}

	window.requestAnimationFrame(updateLax);

	$(window).on('scroll', function () {
		if ($(this).scrollTop() > 150) {
			
			if (!$('.navbar').hasClass('expand')) {
				$('.navbar').addClass('expand');
				//$('#logo').addClass('small')
			}
		} else {
			
			if ($('.navbar').hasClass('expand')) {
				$('.navbar').removeClass('expand');
				//$('#logo').removeClass('small');
			}
		}
	});

	$('.send').click(function(){
		let email = $('#email').val();
		let message = $('#message').val();
		let name = $('#name').val();
		if(email != '' && message != '' && name != ''){
			$.ajax({
				type: "POST",
				url: 'send.php',
				data: {name: name, email: email, message: message},
				success: function(){
					$('.success').html('<h2>Gracias por contactarnos<span>te responderemos a la brevedad</h2>');

					$('#email').val('');
					$('#message').val('');
					$('#name').val('');
					$('.form').slideToggle();
				}
			});
		}else{
			$('.error').fadeIn();
		}
	});

}
